.payment-table-component {
  margin-left: 5px;
  margin-right: 5px;
}

.scrollable-table-container {
  display: flex;
  flex-direction: column;
  max-height: 100%; /* Adjust this height to fit the table within the modal */
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100%;
}

.payment-custom-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.payment-custom-table thead tr th,
.payment-custom-table tfoot tr td {
  background-color: #339af0;
  color: #FFFFFF;
  text-align: center;
  font-weight: bold;
  position: sticky;
  top: 0; /* for thead */
  bottom: 0; /* for tfoot */
  padding: 10px 5px;
  z-index: 10;
}

.payment-custom-table tbody tr td {
  border: 1px solid #ddd;
  text-align: center;
  padding: 10px 5px;
  white-space: nowrap;
}

/* Scrollbar styling for the scrollable container */
.scrollable-table-container::-webkit-scrollbar {
  width: 12px;
}

.scrollable-table-container::-webkit-scrollbar-thumb {
  background: #888888;
  border-radius: 10px;
}

.scrollable-table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.input-radio {
  box-sizing: border-box;
  padding: 0;
  min-width: 18px;
  min-height: 18px;
  transition: all 0.2s ease-in-out;
  position: relative;
  cursor: pointer;
  vertical-align: -4px;
  border: 1px solid;
  border-radius: 100%;
  border-color: #339af0;
  border-width: 7px;
  background-color: white;
}

.hidden-radio:not(:checked) + .input-radio-label .input-radio {
  border-width: 1px;  /* Shrink to no border when deselected */
}

/* Target the .input-radio for the selected state correctly */
.hidden-radio:checked + .input-radio-label .input-radio {
  border-width: 7px;  /* Maintain the border when selected */
}

.hidden-radio {
  display: none; /* Hide the default radio button */
}

.input-radio-label {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  gap: 10px;
}

.input-radio-label .input-radio {
  position: relative;
  left: 0;
  top: 0;
}

/* Ensure label text aligns nicely */
.input-radio-label span {
  vertical-align: middle;
}



.delivery-details-wrapper {
  padding: 10px 8px; 
  border-left: solid 1px #ccc;
  border-right: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  box-sizing: border-box;
  background-color: #f0f0f0;
}





.checkbox-input {
  display: none; /* Hide the default checkbox */
}

.checkbox-input + .checkbox-input-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-input + .checkbox-input-label .checkbox-icon {
  width: 18px;
  height: 18px;
  border: 1px solid #339af0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  box-sizing: border-box;
}

.checkbox-input:checked + .checkbox-input-label .checkbox-icon {
  border-color: #339af0;
  border-width: 9px;
}

.checkbox-input + .checkbox-input-label .checkbox-icon svg {
  fill: transparent;
  align-items: center;
  padding-top: 6px;
  width: 1px; /* Initial width */
  height: 1px; /* Height remains the same */
  transition: width 0.2s ease-in; /* Transition with delay */
  transition: height 0.2s ease-in;
  transform: scale(1.2)
}

.checkbox-input:checked + .checkbox-input-label .checkbox-icon svg {
  fill: #fff;
  width: 18px; /* Final width */
  height: 19px;
}