



.navigation-container {
    height: 100vh;
    border-right: 1px solid #d8dee4
}

.navigation-container.open {
    min-width: 260px;
}

.navigation-container.closed {
    min-width: 70px;
}

.navigation-logo-container {
    height: 64px;
    border-bottom: 1px solid #d8dee4;
    
}

.navigation-logo-wrapper {
    display: flex;
    flex-direction: row;
    padding-left: 16px;
    padding-right: 6px;
    gap: 10px;
    height: 100%;

}

.navigation-overview-wrapper {
    padding-left: 16px;
    padding-right: 16px;

}

.navigation-overview-label {
    font-family: cera-round-pro, "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    letter-spacing: 0.1em;
    font-size: 12px;
    color: #616D8A;
    font-weight: bold;
    height: 32px;
    display: flex;
    align-items: center;

}

.navigation-item {
    display: flex;
    flex-direction: row;
    gap: 5px;
    height: 32px;
    align-items: center;
    vertical-align: middle;
    padding-left: 8px;
}

.navigation-icon {
    font-size: 16px;
    min-width: 32px; 
    min-height: 32px; 
    align-items: center;
    justify-content: center;
    display: flex;
}

.navigation-label {
    font-size: 14px;
    padding-bottom: 2px;
}