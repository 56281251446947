.TwoD-modelling-component {
    border-top: 1px solid #d8dee4;
    min-height: calc(100vh - 62px);
    display: flex;
    flex-direction: column;
}

.modelling-space-container {
    flex: 1;
}

.modelling-space-selector-container {
    border-top: 1px solid #d8dee4;
}

.modelling-space-symbols {

}

.PID-tool {

}