.filter-component {
    position: absolute;
    z-index: 9;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid #ccc;
    border-bottom: none;
    border-radius: 0px;
    height: calc(100vh - 155px + 18px);
    padding-bottom: 18px;
    box-sizing: border-box; 
    width: 200px;
    background-color: white;
}

.filter-component .filter-wrapper {
    flex-grow: 1;
    overflow-y: auto;
    padding-bottom: 0px;
    box-sizing: border-box;
    min-width: 100%;
    max-width: 100%;
}

#filter-button-wrapper {
    width: 100%;
    box-sizing: border-box;
}

#filter-button {
    padding: 10px;
    background-color: #339af0;
    border: none;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    z-index: 1;
    box-sizing: border-box;
}

#filter-button:hover {
    background-color: #1976d2;
}
