.team-table-header {
    font-family: var(--font-family-1);
    font-size: 11px;
    color: #1A1B25;
    font-weight: 600;
    padding: 8px;
  }

  .team-table-header-2 {
    font-family: var(--font-family-1);
    font-size: 12px;
    color: #1A1B25;
    font-weight: 600;
    padding: 8px;
  }

.team-table-td {
    font-family: var(--font-family-1);
    font-size: 14px;
    height: 75px;
    padding: 8px;
    white-space: normal;
    overflow-wrap: anywhere;
    border-bottom: solid 1px #ddd
}

.team-table-td-2 {
    font-family: var(--font-family-1);
    font-size: 14px;
    height: 75px;
    padding: 8px;
    white-space: normal;
    overflow-wrap: anywhere;
}
    






.new-member-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    border: none;
    border-radius: 6px;
    background-color: #339AF0;
    color: white;
    cursor: pointer;
    font-family: var(--font-family-1);
    font-weight: 700;
    gap: 8px; /* space between icon and text */
    transition: background-color 0.1s ease-in;
    white-space: nowrap;
}

.new-member-button:hover {
    background: #007bff;
}