@media (min-width: 1000px) {
    .order-summary__section--product-list .product-table {
        margin-top: 0.75em;
        margin-bottom: 1.5em;
        position: relative;
        z-index: 1;
    }
}

table {
    width: 100%;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 1em;
}

table {
    display: table;
    
    box-sizing: border-box;
    text-indent: initial;
    unicode-bidi: isolate;
    border-spacing: 2px;
    border-color: gray;
}


.product:first-child td {
    padding-top: 0;
}

body.CheckoutEntrypoint * {
    outline-color: #212a2f;
}
.product td {
    padding-top: 1em;
}
.product-table td {
    border-color: #fad9d9;
}
.product-table td {
    border-color: #f0e4bc;
}
.product-table td {
    border-color: #d1ecd4;
}
.product-table th, .product-table td {
    padding-top: 0;
    padding-bottom: 0;
}

td, th {
    padding-left: 1em;
}
td, th {
    padding: 0;
    font-weight: normal;
}

td {
    display: table-cell;
    vertical-align: inherit;
    unicode-bidi: isolate;
}

.visually-hidden {
    border: 0;
    clip: rect(0, 0, 0, 0);
    clip: rect(0 0 0 0);
    width: 2px;
    height: 2px;
    margin: -2px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
}

.order-summary__section {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}

@media (min-width: 1000px) {
    .order-summary__section {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }
}

.order-summary__section~.order-summary__section {
    border-top: 1px solid rgba(175, 175, 175, 0.34);
}

@media (min-width: 1000px) {
    .order-summary__section--product-list {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        min-height: 7em;
        overflow: hidden;
        padding: 0;
        position: relative;
    }
}

@media (min-width: 1000px) {
    .order-summary__section__content {
        min-width: 100%;
        -webkit-overflow-scrolling: touch;
    }
}



.order-summary__section__content {
    flex-grow: 0; /* Allows this section to grow and fill available space */
}

.order-summary__section--total-lines {
    min-height: 200px; /* Ensures this section doesn't shrink below 200px */
}

@media (min-width: 1000px) {
    .order-summary__section--product-list {
        display: flex;
        flex-grow: 0;
        min-height: 7em; /* Minimum height for large screens */
        overflow: hidden;
        position: relative;
    }
}

.sidebar-button {
   
    background-color: white;
    color: black;
    font-size: 9px; 
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; 
    padding: 7px;
    cursor: pointer;
    gap: 5px;
}

.total-line:first-child th, .total-line:first-child td {
    padding-top: 10px;
}

.total-line th, .total-line td {
    padding-top: 0.75em;
}
.total-line-table__tbody td:first-child, 
.total-line-table__tbody th:first-child {
    padding-left: 0;
    text-align: left;
}

.order-summary__emphasis {
    font-weight: 500;
}

.total-line:first-child th::before {
    content: "";
    position: absolute;
    display: block;
    background-color: rgba(175, 175, 175, 0.34);
}

.payment-due__price, .payment-due-label__total{
   
    font-size: 1.7142857143em;
    font-weight: 500;
    letter-spacing: -0.04em;
    line-height: 1em;
}