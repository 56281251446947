.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


:root {
  --font-family-1: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu";
  --font-family-2: "sohne-var", "Helvetica Neue", "Arial", sans-serif;


  --font-color-1: #1A1B25;
  --brand-color-1: #339af0;
  --brand-color-2: #2563EB;

  --inactive-size: 78;
  --icon-scale: 1;
}

.border-ccc-8 {
  border: 1px solid #ccc;
  border-radius: 8px;
}

.border-ddd-8 {
  border: 1px solid #ddd;
  border-radius: 8px;
}

.flex-row-40 {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.flex-row-30 {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.flex-row-20 {
  display: flex;
  flex-direction: row;
  gap: 20px;

}

.flex-row-10 {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.flex-row-0 {
  display: flex;
  flex-direction: row;
  gap: 0px;
}

.flex-row-5 {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.justify-start {
  display: flex;
  justify-content: start;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: end;
}

.align-center {
  display: flex;
  align-items: center;
}

.align-right {
  display: flex;
  align-items: right;
}

.flex-col-40 {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.flex-col-30 {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.flex-col-20 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.flex-col-10 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.flex-col-5 {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.flex-col-0 {
  display: flex;
  flex-direction: column;
}

.zp-font-small,
.zp-font-s {
  font-size: 12px;
  font-family: var(--font-family-1);
}

.zp-font-medium,
.zp-font-m {
  font-size: 14px;
  font-family: var(--font-family-1);
}

.zp-font-large,
.zp-font-l {
  font-size: 16px;
  font-family: var(--font-family-1);
}

.zp-font-xlarge,
.zp-font-xl {
  font-size: 20px;
  font-family: var(--font-family-1);
}

.zp-font-xxlarge,
.zp-font-xxl {
  font-size: 24px;
  font-family: var(--font-family-1);
}

.zp-font-xxxl {
  font-size: 28px;
  font-family: var(--font-family-1);
}

.zp-weight-none {
  font-weight: 0
}

.zp-weight-s {
  font-weight: 450
}

.zp-weight-m {
  font-weight: 500
}

.zp-weight-l {
  font-weight: 700
}

.zp-transition-200 {
  transition: ease-in-out 200ms background-color;
}

.hover-style-1:hover {
  background-color: #D8DEE4
}

.hover-style-2:hover {
  background-color: #f7f7f7;
  transition: ease-in-out 200ms background-color;
  cursor: pointer
}

.nowrap {
  flex-wrap: nowrap;
  white-space: nowrap;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrap {
  flex-wrap: wrap;
  white-space: normal;
  overflow-wrap: break-word; /* helps with long words or URLs */
  word-break: break-word;    /* fallback for older browsers */
}


.is-small-900 {
  display: none;
}



@media (max-width: 900px) {
  .is-small-900 {
    display: flex;
  }
}

.download-link a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 8px;
}

.download-link:hover {
  color: var(--brand-color-2);
  text-decoration: underline;
}










input:disabled {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE/Edge */
}


.zp-w-100 {
  width: 100%;
}

.zp-w-80 {
  width: 80%;
}

.zp-w-70 {
  width: 70%;
}

.zp-w-20 {
  width: 20%;
}

.zp-w-30 {
  width: 30%;
}

.zp-h-100 {
  height: 100%;
}

.new-member-left {
  padding: 32px 32px 0px 32px;
  border-right: 1px solid #ccc;
}

@media (max-width: 750px) {
  .hide-750 {
    display: flex;
    flex-direction: column;
  }

  .new-member-right {
    border-top: 1px solid #ccc;
  }

  .new-member-left {
    padding: 20px;
    border-right: none;
  }
}

@media (max-width: 450px) {
  .hide-450 {
    display: none !important;
  }
}


.rotate {
  animation: rotation 500ms infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}