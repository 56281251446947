/* Steps progressbar */
.steps-progress-bar {
    display: flex;
    justify-content: left;
    padding: 0;
    list-style: none;
    position: relative;
    
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0px; /* Adjust the margin to space out the steps */
}

.step-chevron {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px; /* Adjust the margin to space out the chevrons */
}

.step-content {
    padding: 10px;
    border: 2px solid #dddddd;
    border-radius: 5px;
    text-align: center;
    background: #fff;
    position: relative; /* For z-index context */
    z-index: 2; /* Above the lines */
}

.step-number {
    font-weight: bold;
    color: #0072DC;
    margin-bottom: 5px;
}

.step-name {
    color: #757575;
    transition: color 0.1s ease, font-weight 0.1s ease;
}

.step.active .step-content {
    border-color: #0072DC;
}

/* Connecting lines between the steps */
.step-line {
    height: 2px;
    background: #dddddd;
    flex-grow: 1; /* Lines take up the available space between steps */
    position: center; /* For z-index context */
    z-index: 1; /* Below the step content */
    margin-top: 45px;
    
}

.step-line.active{
    background: #0072DC; /* Active line color */
}

.active-step {
    color: #339af0; /* Blue color for the active step */
    font-weight: bold; /* Bold font weight for the active step */
}