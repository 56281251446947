.outlook-login-button {
    padding: 0px 12px 0px 12px; 
    width: 100%; 
    height: 52px; 
    cursor: pointer; 
    font-size: 14px; 
    display:flex; 
    align-items: center;
    background: #fff;
    border: 1px solid #DADCE0;
    border-radius: 4px;
    transition: .15s ease-in-out; 
    background-color: .15s ease-in-out;
    color: #2d333a;
    font-family: "Google Sans",arial,sans-serif;
    letter-spacing: 0.25px;
    
}

.outlook-login-button:hover {
    background-color: #EEEEEE;
}

.outlook-login-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    transform: translateX(-160%) translateY(-50%);
}

.google-login-text {
    text-align: left;
}


.google-signin-container {
    width: 320px; /* Set a fixed width */
    height: 52px; /* Set a fixed height */
    
    overflow: hidden; /* Prevent content overflow */
    
    display: flex; /* Use flexbox to center content */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    position: relative; /* Ensure proper positioning */

    margin-bottom: 10px;
}

.google-signin-container iframe {
    width: 320px ; /* Ensure the iframe takes the full width */
    height: 52px ; /* Ensure the iframe takes the full height */
    /*min-width: 300px !important;*/
    /*min-height: 300px !important;*/
    /*margin: 0 !important;*/
    
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    /*display: none !important*/
}

.google-signin-container #container {
    width: 300px
}

.google-signin-button {
    width: 320px !important;
    height: 52px !important;
}



.google-signin-container > div > div > div > div > div {
    /* width: 100% !important;*/
    height: 10px !important;
}

.google-signin-container span {
    text-align: left !important;
}

/* Additional styling to ensure the iframe respects the container's dimensions */


/*width: 100% !important;
    height: 100% !important;*/

