/* TableComponent.css */

.table-component {
  
  overflow-x: hidden; /* Hides horizontal overflow */
  overflow-y: scroll;
  position: relative;
  height: calc(100%);
  box-sizing: border-box; /* Border and padding are included in the width/height */
  }

  .table-fixed, 
  .table-fixed2 {
    border-collapse: collapse;
    border: 1px solid #ddd;
    margin: 0 0;
    font-size: 0.9em;
    border-radius: 5px 5px 0 0;
    flex-shrink: 0; /* Prevents the table from shrinking */
    flex-grow: 0; /* Prevents the table from growing */
    
  }

  .table-overflow,
  .more-details-table {
    border-collapse: collapse;
    border: 1px solid #ddd;
    margin: 0 0;
    font-size: 0.9em;
    border-radius: 5px 5px 0 0;
    overflow-x: hidden;
    overflow-y: hidden;
    flex-grow: 1;
  }

  .table-overflow table,
  .more-details-table table {
    width: 100%;
  }

  

  .table-fixed thead,
  .table-fixed2 thead,
  .table-overflow thead,
  .more-details-table thead {
    border: 1px solid #ddd;
    text-align: left;
    font-size: 0.9em;; /* Set font size */
    white-space: normal; /* Text wraps normally */
  }

  .table-fixed tbody tr,
  .table-fixed2 tbody tr,
  .table-overflow tbody tr,
  .more-details-table tbody tr {
    height: 113px;
    
    box-sizing: border-box;
  }
  
  .more-details-table table {
    min-width: 400px;
  }

  .more-details-table tbody tr table tr {
    height: 30px;
    box-sizing: border-box;
  }

  .table-overflow tbody tr, 
  .table-overflow tbody td {
    min-width: 235px;
    border-right: 1px #ddd solid;
    border-bottom: 1px #ddd solid;
    box-sizing: border-box;
  }

  .table-fixed tbody tr,
  .table-fixed tbody td,
  .table-fixed2 tbody tr,
  .table-fixed2 tbody td,
  .table-fixed thead th, 
  .table-fixed2 thead th,
  .table-overflow thead th,
  .more-details-table thead th {
    min-width: 200px;
  }
  

  /*.table-fixed tbody tr:nth-of-type(even):hover,
  .table-fixed tbody tr:nth-of-type(odd):hover,
  .table-fixed2 tbody tr:nth-of-type(even):hover,
  .table-fixed2 tbody tr:nth-of-type(odd):hover,*/
  .table-overflow tbody tr:nth-of-type(even):hover,
  .table-overflow tbody tr:nth-of-type(odd):hover {
    background-color: #339af0;
    color: #FFFFFF;
  }
  
  .table-fixed thead tr,
  .table-fixed2 thead tr,
  .table-overflow thead tr,
  .more-details-table thead tr {
    background-color: #339af0;
    color: #FFFFFF;
    text-align: center;
  }

  .tables-container {
    display: flex;
    flex-direction: row;
    min-height: 100%;
  }

  .table-fixed tbody tr:nth-of-type(even),
  .table-fixed2 tbody tr:nth-of-type(even),
  .table-overflow tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  .table-fixed thead tr th,
  .table-fixed2 thead tr th, 
  .table-overflow thead tr th,
  .more-details-table thead tr th {
    text-align: center; /* Centers header cell content */
    padding: 10px 5px;
  }

  .table-fixed2 tbody tr td,
  .table-overflow tbody tr td {
    text-align: center; /* Centers data cell content */
  }
  
  .table-fixed tbody tr td {
    text-align: left;
  }


  .fixed-bottom {
    margin-top: auto; /* Pushes itself to the bottom */
    width: 100%; /* Ensures it spans the full width of its container */
    text-align: center; /* Centers the text inside the div */
    background-color: #f2f2f2; /* Gives a background color */
    flex-shrink: 0; /* Prevents the table from shrinking */
    flex-grow: 0; /* Prevents the table from growing */
    overflow-x: auto;  /* Enables horizontal scrolling */
    overflow-y: hidden; /* Prevents vertical scrolling */
}

  .more-details-button {
    background: "white";
    color: "black";
    
    box-sizing: "border-box"; 
    padding: "7px";
    min-width: "7px";
    cursor: "pointer";
    text-align: "center";
  }

  .more-details-button:hover {
    background-color: #ddd; /* Lighter grey on hover */
  }

  .more-details-button-highlight {
    background-color: #4CAF50; /* Green background */
    color: white; /* White text */
  }

  .more-details-table {
    min-height: 100%
  }








  
  .custom-table {
    border-collapse: collapse;
    border: 1px solid #ddd;
    margin: 0 0;
    font-size: 0.9em;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    margin-right: 0px;
  }
  
  .custom-table thead, .custom-table td {
    border: 1px solid #ddd;
    text-align: left;
    font-size: 13px; /* Set font size */
    
    white-space: normal; /* Text wraps normally */
  }
  
  .custom-table thead {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  .custom-table thead tr {
      background-color: #339af0;
      color: #FFFFFF;
      text-align: left;
      padding: 10px 5px;
  }

  .custom-table thead tr th,
  .custom-table td {
      padding: 10px 5px;
  }

  .custom-table td {
    min-height: 100px;
  }

  .custom-table tbody tr {
      border-bottom: 1px solid #dddddd;
      justify-items: center;
  }

  .custom-table tbody tr:nth-of-type(even) {
      background-color: #f3f3f3;
  }

  .custom-table tbody tr:last-of-type {
      border-bottom: 2px solid #339af0;
  }
 
  .custom-table tbody tr td {
    text-align: center;
  }

  .custom-table thead tr {
    text-align: center;
    
  }

  .skeleton-row {
    display: flex;
    justify-content: space-between;
  }

  .custom-table tbody tr:hover {
    background-color: #339af0;
    color: #FFFFFF;
  }



  /* Expanded Row Styles */




.detail-row td {
  background-color: white; /* Sets the background color of the expanded row to white */
}

.detail-row .custom-table tbody tr:not(.detail-files):hover {
  background-color: inherit; /* Disables highlighting on hover for detail rows */
}

.detail-files:hover {
  background-color: transparent !important; /* Ensures no change on hover for CAD and Tech files */
}


.config-option-button {
  /* other styles */

  border-left: 1px solid #ccc; /* Default border style for non-selected state */
}

.config-option-button.selected {
  border-left: 4px solid #339AF0;
}

.config-option-button.selected:hover {
  border-left: 4px solid #1976D2;
}



/* CustomerEquipmentComponent.css */
.quantity-changed {
  color: #339AF0;
  font-weight: bold;
  
}

.quantity-changed-fade {
  color: black;
  font-weight: normal;
  transition: color 1s, font-weight 1s;
}
