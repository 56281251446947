:global(.hiddenRadio:checked+.inputRadioLabel .inputRadio) {
    border-width: 7px;
}

.inputRadioLabel .inputRadio {
    position: relative;
    left: 0;
    top: 0;
}

.inputRadioLabel span {
    vertical-align: middle;
}

.inputRadio {
    box-sizing: border-box;
    padding: 0;
    min-width: 18px;
    min-height: 18px;
    transition: all 0.2s ease-in-out;
    
    cursor: pointer;
    
    border: 1px solid;
    border-radius: 100%;
    border-color: #339af0;
 
    background-color: white;
}

.inputRadioLabel {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    gap: 10px;
 
}

.hiddenRadio {
    display: none;
}