.form-group {
    margin-bottom: 5px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-family: "Arial", sans-serif;
    font-size: 13px;
}

.form-group input {
    width: 100%;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s;

}

.location-container {
    display: flex;
    gap: 10px;
}

.location-group {
    position: relative;
}

.location-group input {
    width: 100%;
    padding: 8px 10px;
    padding-top: 18px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}


.location-group label {
    position: absolute;
    left: 10px;
    top: 13px;
    color: #999;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}



.location-group input:focus + label,
.location-group input:not(:placeholder-shown) + label {
    top: 0px;
    left: 10px;
    font-size: 12px;
    color: #333;
}

.form-group-wrapper {
    padding-top: 5px;
    padding-bottom: 0px;   
    width: 100%;
    box-sizing: border-box;
}

.form-group-wrapper-confirm {
    margin-top: 5px;
    padding-bottom: 0px;   
    width: 100%;
    border: 1px solid #ccc;
    background-color: #F0F0F0;
    border-radius: 4px;
    box-sizing: border-box;
}

.change-btn {
    margin-left: auto;
    border: none; 
    background-color: transparent; 
    font-weight: bold; 
    color: #339af0;
    cursor: pointer;
}



.location-group-confirm {
    position: relative;
}

.location-group-confirm input {
    width: 100%;
    padding: 8px 10px;
    padding-top: 18px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
}



.location-group-confirm label {
    position: absolute;
    left: 10px;
    top: 13px;
    color: #999;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}



.location-group-confirm input:focus + label,
.location-group-confirm input:not(:placeholder-shown) + label {
    top: 5px;
    left: 10px;
    font-size: 12px;
    color: #333;
}