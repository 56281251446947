.save-button-css {
    max-width: 400px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: var(--font-family-1);
    padding: 4px 12px;
    background-color: var(--brand-color-1);
    color: white;
    font-weight: 500;
    border-radius: 4px;
    transition: background-color 0.15s ease-in-out
}

.save-button-css:hover {
    background-color: var(--brand-color-2);
    cursor: pointer
}

.cancel-button-css {
    max-width: 400px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: var(--font-family-1);
    padding: 4px 12px;
    background-color: #fff;
    color: black;
    font-weight: 500;
    border-radius: 4px;
    border: 1px solid #ddd;
    transition: background-color 0.15s ease-in-out

}

.cancel-button-css:hover {
    background-color: #eee;
    cursor: pointer
}

.bank-widget {
    display: flex;
}

@media (max-width: 400px) {
    .bank-widget {
        display: none;
    }
}

.plans-widget {
    display: flex;
}

@media (max-width: 700px) {
    .plans-widget {
        display: none;
    }
}








  .curve-animation {
    stroke-dasharray: 400; /* Total length of the path (adjust to match path length) */
    stroke-dashoffset: 400; /* Initially offset by the total length */
    stroke: red;
    
    fill: none; /* Ensure the curve is not filled */
    animation: draw-curve 2s linear infinite; /* Animate infinitely */
  }

  @keyframes draw-curve {
    0% {
      stroke-dashoffset: 400; /* Start hidden */
    }
    50% {
      stroke-dashoffset: 0; /* Curve fully drawn */
    }
    100% {
      stroke-dashoffset: -400; /* Start point moves to end */
    }
  }


  .line-curve-animation {
    stroke-dasharray: 500; /* Adjust to match the total path length */
    stroke-dashoffset: 500; /* Initially offset by the total length */
    stroke: blue;
   
    fill: none; /* Ensure no fill */
    animation: draw-path 2s linear infinite; /* Infinite animation */
  }


  @keyframes draw-path {
    0% {
      stroke-dashoffset: 500; /* Path is fully hidden */
    }
    30% {
      stroke-dashoffset: 0; /* Path is fully revealed */
    }
    70% {
        stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: -500; /* Path flows continuously */
    }
  }


  .homepage-suite-animation {
    display: grid;
    grid-template-columns: repeat(6, 78px);
    grid-template-areas: 
      ". . fat-sat . . ."
      ". ITPS . Commissioning . Document-Control";
    gap: 12px;
  }
  
  .icon {
    --inactive-size: 78;
    --icon-scale: 1;
    position: relative;
    width: calc(1px * var(--inactive-size));
    height: calc(1px * var(--inactive-size));
    transform: scale(var(--icon-scale));
    transform-origin: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    
  }

  .arrow {
    width: 78px;
    height: 78px;
  }
  
  .fat-sat {
    grid-area: fat-sat;
    animation: animate-icon-scale 10s infinite;
    pointer-events: none; /* Disable pointer events during animation */
  }
  
  .ITPS {
    grid-area: ITPS;
  }
  
  .Commissioning {
    grid-area: Commissioning;
  }
  
  .Document-Control {
    grid-area: Document-Control;
  }
  
  /* Scale up on hover */
  .icon.fat-sat:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
    
  }
  
  @keyframes animate-icon-scale {
    0% {
      transform: scale(1);
      pointer-events: auto; /* Disable interactions during the scaling animation */
    }
    10% {
      transform: scale(1.1);
      pointer-events: none; /* Keep interactions disabled */
    }
    15% {
      transform: scale(1);
      pointer-events: auto; /* Keep interactions disabled */
    }
    
  }
  