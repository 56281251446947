

.settings-nav-grid {
    display: grid; 
    grid-template-columns: repeat(3, minmax(300px, 410px));
    gap: 10px;;
}

@media (max-width: 1200px) {
    .settings-nav-grid {
        grid-template-columns: repeat(2, minmax(300px, 410px));
    }
}

.settings-button-wrapper {
    min-height: 80px;
}

@media (max-width: 650px) {
    .settings-nav-grid {
        grid-template-columns: repeat(1, minmax(300px, 410px));
    }
    .settings-button-wrapper {
        min-height: 1px;
    }
}

.settings-button-container:hover {
    background-color: #f7f7f7;
    cursor: pointer
}



.settings-button-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 5px;
    border-radius: 8px;
    transition: background-color 0.2s ease-in-out;
}