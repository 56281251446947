
:root {
    --modal-background-color: rgb(41,47,76,0.7)
}

.modal-style {
    z-index: 999;
    position: absolute;
    border: 1px solid white;
    background-color: white;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 200px;
}

.cancel-button {
    padding: 8px 16px;
    border-radius: 6px;
    
}

.delete-button {
    padding: 8px 16px;
    border-radius: 6px;
    background-color:#0073ea;
    color: white;
}

.delete-button:hover {
    background-color: #0061c9;
    cursor: pointer
}

.cancel-button:hover {
    background-color: rgb(233, 233, 233);
    cursor: pointer
}

.modal-header {
    font-size: 20px;
}

.modal-text-content {

}
