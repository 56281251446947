.button1, .button2, .button3, .button4 {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none;
}



.button1 {
    color: var(--brand-color-1);
    display: flex;
    align-items: center;
}

.button1:hover {
    color: var(--brand-color-2);
    cursor: pointer;
    transition: color 100ms ease-in-out
}

.button2 {
    height: 100%;
    width: 100%;
    font-family: var(--font-family-1);
    border: 1px solid #ccc;
    border-radius: 4px;
    display: flex;
    justify-content: start;
    align-items: center;

    transition: background-color 200ms ease-in-out, border 200ms ease-in-out;
}

.button2:hover {
    background-color: #f7f7f7;
    transition: border 200ms ease-in-out, background-color 200ms ease-in-out;
    border: 1px solid var(--brand-color-1);
    cursor: pointer;
}

.button3 {
    font-family: var(--font-family-1);
    color: white;
    background-color: var(--brand-color-1);
    transition: background-color 200ms ease-in-out;
}

.button3:hover {
    background-color: var(--brand-color-2);
    cursor: pointer;
    transition: background-color 200ms ease-in-out;
    
}



.button4 {
    font-family: var(--font-family-1);
    font-weight: 500;
    cursor: pointer;
    border: 1px solid #ddd;
    transition: border 100ms ease-in-out;
}

.button4:hover {
    border: 1px solid #aaa;
    transition: border 100ms ease-in-out;
}

.button4_1 {
    font-family: var(--font-family-1);
    font-weight: 500;
    color: white;
    background-color: var(--brand-color-1);
    cursor: pointer;
    
}






.button5 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    border: none;
    border-radius: 6px;
    background-color: #339AF0;
    color: white;
    cursor: pointer;
    font-family: var(--font-family-1);
    font-weight: 700;
    gap: 8px; /* space between icon and text */
    transition: background-color 0.1s ease-in;
    white-space: nowrap;
    height: 100%;
}

.button5:hover {
    background: #007bff;
}