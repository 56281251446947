
.button3 {
    font-family: var(--font-family-1);
    color: white;
    background-color: var(--brand-color-1);
    transition: background-color 200ms ease-in-out;
}

.button3:hover {
    background-color: var(--brand-color-2);
    cursor: pointer;
    transition: background-color 200ms ease-in-out;
    
}
