.modal-wrapper {
    z-index: 998;
    position: fixed;
    background-color: var(--modal-background-color);
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
}

.modal-container {
    position: absolute;
    border: 1px solid white;
    background-color: white;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    border-radius: 8px;
    padding: 16px;
}


.modal-wrapper-2 {
    

    grid-template-rows: minmax(40px, 1fr) auto minmax(40px, 2fr);
    grid-template-columns: 40px 8fr 40px;
    place-items: center;
    top: 0px;
    left: 0px;
    position: fixed;
    background-color: var(--modal-background-color);
    height: 100%;
    width: 100%;
    display: grid;
    overflow: hidden;
    
}

.modal-container-2 {
    grid-column: 2;
    grid-row: 2;
    height: 100%;
    max-height: calc(var(--vh) - 2*40px);
    width: 100%;
    border: 1px solid white;
    background-color: white;
    width: 400px;
    border-radius: 8px;
    padding: 16px;
    max-width: min(calc(100vw - 40px* 2), 1350px);
}