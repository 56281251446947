.file-upload-container {
    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 8px;
    background-color: transparent;
    transition: background-color 0.1s ease-in-out;
    min-width: 100%;
}

.file-upload-container:hover {
    background-color: #F6FAFE;
}



.dropped-files-row {
    display: flex; 
    flex-direction: row; 
    gap: 5px;
    padding: 8px;
    background-color: transparent;
    transition: background-color 0.1s ease-in-out;
}

.dropped-files-row:hover {
    background-color: #F6FAFE;
    cursor: pointer;
}