.visibility-control-panel {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px;
    margin-right: 5px; /* Spacing between this component and the next */
    
    box-sizing: 'border-box';
    border-radius: 4px;
    
    
}

.visibility-control-panel button {
    display: block;
    margin-right: 10px;
    max-width: 120px;
    min-width: 120px;
    padding: 10px;
    background-color: white;
    border: 1px solid #ccc;
    
    color: black;
    border-radius: 4px;
    cursor: pointer;
}

.visibility-control-panel button:hover {
    background-color: #efefef;
}


.visibility-control-panel button.selected {
    display: block;
    
    width: 100%;
    padding: 10px;
    background-color: #339af0;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.visibility-control-panel button.selected:hover {
    background-color: #1976d2;
}