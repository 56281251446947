
.customer-orders {
    width: 50%;
    margin: 50px auto;
    border: solid #ccc 2px;
    border-radius: 8px;
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
    min-width: 850px;
    max-width: 850px;
    min-height: 600px;   
}

.customer-orders h2 {
    color: #333;
    margin-bottom: 15px;
    text-align: center;
}

.table-container {
    max-height: 400px; /* Adjust based on your needs */
    overflow-y: auto;
    border: solid 1px #ccc;
    margin-top: 20px; /* Optional: Adds some space between the search input and the table */
}

.orders-table {
    width: 100%;
    border-collapse: collapse;
    
}

.orders-table th {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #ccc;
}

.orders-table td {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #ccc;
    
}

.orders-table td:not(:last-child) {
    border-left: 1px solid #ccc;
}

.orders-table td:first-child {
    border-left: 0
}


.orders-table th {
    background-color: #f4f4f4;
    color: #333;
}

.orders-table tr:hover {
    background-color: #f4f4f4;
}

.toggle-button {
    padding: 10px 20px;
    margin-bottom: 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.toggle-button:hover {
    background-color: #0056b3;
}

/* Adjustments for skeleton loading */
.skeleton-row td {
    padding: 12px;
}

.skeleton-row {
    border-bottom: 1px solid #ccc;
}

.invoice-link a {
    color: #007bff; /* Blue color for links */
    text-decoration: none; /* Removes underline from links */
    font-weight: bold; /* Makes text bold */
    display: flex; /* Aligns icon with text */
    align-items: center; /* Centers items vertically */
    gap: 8px; /* Adds space between text and icon */
}

.invoice-link a:hover, .invoice-link a:focus {
    color: #0056b3; /* Darker blue on hover/focus for better accessibility */
    text-decoration: underline; /* Adds underline on hover/focus */
}

.orders-search-input {
    padding: 8px 15px;
    margin-bottom: 20px;
    border: 1px solid #ddd; /* Light grey border */
    border-radius: 4px; /* Rounded borders */
    font-size: 16px; /* Increased font size for better readability */
    width: 300px; /* Set a fixed width 300px*/
    box-shadow: 0px 2px 5px rgba(0,0,0,0.1); /* Subtle shadow for depth */
    transition: border-color 0.05s ease-in-out; /* Smooth transition for interactions */
}

.orders-search-input:focus {
    outline: none; /* Removes the outline on focus */
    border-color: #007bff; /* Changes border color to indicate focus */
    box-shadow: 0px 2px 8px rgba(0,76,152,0.2); /* More pronounced shadow on focus */
}