

.CheckoutEntryPoint {
    box-sizing: border-box;
}

body {
    overflow-y: hidden
}

body.CheckoutEntryPoint {
    font-family: Geograph, Helvetica, Arial, sans-serif !important;
    font-size: 14px;
    font-weight: 400 !important;
    letter-spacing: .5px;
    line-height: 1.5;
    font-smooth: auto;
    -webkit-font-smoothing: auto;
}

.content {
    overflow: hidden;
    
}

.anyflexbox body, .anyflexbox .content, .anyflexbox .content .wrap, .anyflexbox .main {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
}



@media (min-width: 1000px) {
    .anyflexbox .content .wrap {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
    }
}

@media (min-width: 1000px) {
    .page--no-banner .main, .page--no-banner .sidebar {
        padding-top: 4em;
    }
}

@media (min-width: 1000px) {
    .main {
        width: 52%;
        width: 52%;
        padding-right: 6%;
        float: left;
    }
}

.anyflexbox .content .wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
}
.anyflexbox body, .anyflexbox .content, .anyflexbox .content .wrap, .anyflexbox .main {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
}
body.CheckoutEntrypoint * {
    outline-color: #212a2f;
}



div {
    display: block;
    unicode-bidi: isolate;
}


@media (min-width: 1000px) {
    .anyflexbox .content .wrap {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
    }
}

.anyflexbox .content .wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
}
.anyflexbox body, .anyflexbox .content, .anyflexbox .content .wrap, .anyflexbox .main {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
}
body.CheckoutEntrypoint * {
    outline-color: #212a2f;
}

.wrap {
    overflow-x: visible;
    width: 80%;
    height: 100%;
    margin: 0 auto;
    display: "flex";
    flex-direction: column;
    max-width: 40em;
    zoom: 1;
}

@media (min-width: 1000px) {
    .wrap {
        width: 90%;
        max-width: 78.5714285714em;
    }
}


.wrap {
    display: block;
    margin: 0 auto;
    max-width:  78.5714285714em;
    zoom: 1;
}

@media (min-width: 1000px) {
.main, .sidebar {
    padding-top: 4em;
    
}
}
body.CheckoutEntrypoint * {
    outline-color: #212a2f;
}
@media (min-width: 1000px) {
.sidebar {
    width: 42%;
    padding-left: 4%;
    background-position: left top;
    float: right;
    max-height: 1150px
}
}
.sidebar {
    position: relative;
    color: #535353;
    
}

@media (min-width: 1000px) {
.sidebar::after {
    left: 0;
    background-position: left top;
    -webkit-box-shadow: 1px 0 0 #e1e1e1 inset;
    box-shadow: 1px 0 0 #e1e1e1 inset;
}
}
.sidebar::after {
    content: "";
    display: block;
    height: 300%;
    width: 300%;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}


body.CheckoutEntrypoint .step__footer {
    margin-bottom: 16px;
}

@media (min-width: 750px) {
    .step__footer {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}
body.CheckoutEntrypoint * {
    outline-color: #212a2f;
}
@media (min-width: 750px) {
    .step__footer {
        margin-top: 1.5em;
        margin-bottom: 16px;
    }
}
.step__footer {
    z-index: 2;
    position: relative;
    margin-top: 1em;
}


body.CheckoutEntrypoint .step__footer {
    margin-bottom: 16px;
}


@media only screen and (min-width: 992px) {
    body.CheckoutEntrypoint .step__footer__continue-btn {
        margin-bottom: 0;
    }
}


@media only screen and (min-width: 992px) {
    body.CheckoutEntrypoint .btn {
        display: block;
    }
}

@media (min-width: 750px) {
    .step__footer__continue-btn {
        float: right;
    }
}

@media (max-width: 750px) {
    .step__footer__continue-btn {
        width: 100%;
    }
}


body.CheckoutEntrypoint .step__footer__continue-btn {
    box-shadow: none;
    margin-bottom: 16px;
}



body.CheckoutEntrypoint .btn, body.CheckoutEntrypoint .content-box, body.CheckoutEntrypoint .field__input, body.CheckoutEntrypoint .notice, body.CheckoutEntrypoint .product-thumbnail, body.CheckoutEntrypoint .product-thumbnail:after, body.CheckoutEntrypoint .product-thumbnail__wrapper, body.CheckoutEntrypoint button, body.CheckoutEntrypoint input {
    border-radius: 0;
}
body.CheckoutEntrypoint * {
    outline-color: #212a2f;
}

body.CheckoutEntryPoint .btn {
    display: block; /* Consistent display property */
    border: 2px solid #339af0;
    border-radius: 2px;
    
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2px;
    padding: 13px 18px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    appearance: none;
    box-sizing: border-box;
    transition: background-color 0.25s ease-out, color 0.25s ease-out, border-color 0.25s ease-out, box-shadow 0.2s ease-in-out; /* Consolidated transition */
    
}

.btn:not(:hover) {
    background-color: #339af0;  /* Explicitly defining the non-hover state */
    color: #fafafa;
}

.btn:hover {
    background-color: #fff;  
    color: #212a2f;
}


.btn__content {
    position: relative;
    -webkit-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
}