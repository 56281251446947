/* Root container */
.date-range-picker {
    position: relative;
    display: inline-block;
    font-family: Arial, sans-serif;
  }
  
  /* Input field styling */
  .date-input {
    padding: 10px;
    width: 260px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Calendar popover */
  .calendar-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 8px;
    border: 1px solid #ccc;
    background: #fff;
    padding: 10px;
    z-index: 1000;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    
  }
  
  /* Navigation buttons */
  .calendar-nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  
  .calendar-nav button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    padding: 5px;
  }
  
  /* The container for months (no transitions now) */
  .months-container {
    /* no special positioning needed if we're not animating */
  }
  
  .months-flex {
    display: flex;
    gap: 20px;
  }
  
  /* Single month panel */
  .calendar-panel {
    width: 240px;
  }
  
  .month-title {
    text-align: center;
    font-size: 14px;
    margin-bottom: 6px;
  }
  
  /* Weekday headings */
  .weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    margin-bottom: 4px;
    font-size: 12px;
    color: #aaa;
  }
  
  .weekdays-cell {
    padding: 4px 0;
    
  }
  
  /* Days grid */
  .days-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    font-size: 14px;
    
  }

  .day-cell-wrapper:not(.in-range):not(.selected):hover {
    background-color: #eee;
    border-radius: 32px;
  }

  .day-disabled:hover {
    background-color: white;
  }

  







  .day-cell-wrapper {
    height: 32px;
    
    margin-top: 5px;
    margin-bottom: 5px;
    /* Optionally add a transition for hover highlight */
  }
  
  .day-cell-wrapper.in-range {
    background-color: #d0e2ff;
    border-radius: 0px;
    color: rgb(78, 78, 255)
  }

  .day-cell-wrapper.in-range.start-of-month {
    border-bottom-left-radius: 32px;
    border-top-left-radius: 32px;
  }

  .day-cell-wrapper.in-range.end-of-month {
    border-bottom-right-radius: 32px;
    border-top-right-radius: 32px;
  }

  .day-cell-wrapper.in-range .day-cell:not(.hovered-end):hover {
    background-color: #aecdff;
    border-radius: 32px;
  }
  
  .day-cell-wrapper.selected {
    background-color: #d0e2ff;
    color: #fff;
    position: relative;
  }

  .day-cell-wrapper.selected.left {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 32px;
    border-top-right-radius: 0px;
    border-top-left-radius: 32px;
  }
  
  .day-cell-wrapper.selected.right {
    border-bottom-right-radius: 32px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 32px;
    border-top-left-radius: 0px;
  }

  .day-cell-wrapper.selected.right.start-row,
  .day-cell-wrapper.selected.left.end-row {
    border-radius: 32px;
  }



  .day-cell-wrapper.selected.left.right,
  .day-cell-wrapper.same-day {
    border-radius: 32px;
  }


  .day-cell-wrapper.hovered-end {
    background-color: #d0e2ff;
    color: #fff;
    position: relative;
    border-bottom-right-radius: 32px;
    border-top-right-radius: 32px;
  }

  .day-cell-wrapper.end-row {
    border-bottom-right-radius: 32px;
    border-top-right-radius: 32px;
  }

  .day-cell-wrapper.start-row {
    border-bottom-left-radius: 32px;
    border-top-left-radius: 32px;
  }

  .day-cell.hovered-end {
    background-color: #007bff;
    position:absolute;
    width: 100%;
    border-radius: 32px;
  }
  
  /* The inner day-cell can remain unstyled or minimal styling */
  .day-cell {
    height: 100%;
    line-height: 32px;
    cursor: pointer;
    user-select: none;
    /* No background on .day-cell so the wrapper highlight shows through */
  }

  .day-cell.selected {
    position: absolute;
    width: 100%;
    background: #007bff;
    border-radius: 32px;
  }

  .day-cell.day-disabled {
    color: #ccc;
    cursor:default;
  }



  .calender-shortcut-button {
    text-wrap: nowrap;
    font-size: 12px;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
  }

  .calender-shortcut-button.hover-shortcut:not(.selected-shortcut) {
    background-color: #ccc;
  
  }

  .calender-shortcut-button.selected-shortcut {
    background-color: #007bff;
    color: white;
  }

  .change-month-button {
    cursor: pointer;
    border-radius: 4px;    
  }

  .change-month-button-icon {
    width: 100%;
    
    transition: color 0.1s ease-in-out;
  }

  .change-month-button-icon:hover {
    color: #aaa;
  }

  .left-month-label {
    display: flex;
    flex-direction: row;
    width: 240px;
    justify-content: space-between;
    font-size: 14px;
  }

  .change-month-button.small-screen {
    display: none
  }

  .input-buttons-large-screen {
    display: flex;
  }

  .input-buttons-small-screen {
    display: none;
  }

  .shortcut-buttons {
    display: flex
  }

  

  @media (max-width: 400px) {
    .shortcut-buttons {
      display: none;
    }

    .months-container-small-screen {
      justify-content: center;
    }
  }

  @media (max-width: 670px) {
    .left-month {
      display: none;
    }

    .left-month-label {
      display: none;
    }

    .change-month-button.small-screen {
      display: block;
    }

    .calendar-nav {
      justify-content: center;
    }

    .months-flex {
      justify-content: center;
    }

    .input-buttons-large-screen {
      display: none;
    }

    .input-buttons-small-screen {
      display: flex;
      margin-bottom: 20px;
      justify-content: center;
    }
  }