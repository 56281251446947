    .custom-alert {
        padding: 15px;
        margin: 10px 0;
        border: 1px solid transparent;
        border-radius: 4px;
        background-color: #fff3cd;
        color: #856404;
        border-color: #ffeeba;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: opacity 0.5s ease-in-out;
        margin-bottom: 10px; /* Margin between alerts */
    }

    .custom-alert-success {
        padding: 15px;
        margin: 10px 0;
        border: 1px solid transparent;
        border-radius: 4px;
        background-color: #d4edda;  /* Light green for background */
        color: #155724;  /* Dark green for text */
        border-color: #c3e6cb;  /* Soft green for border */
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: opacity 0.5s ease-in-out;
        margin-bottom: 10px; /* Margin between alerts */
    }

    .custom-alert-symbol {
        color: '#ed6c02'
    }

    .custom-alert.fade-in-out {
        opacity: 1;
        animation: fade-in-out 5s forwards;
    }

    @keyframes fade-in-out {
        0% {
            opacity: 1;
        }
        5% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
    }

    .custom-alert-close-warning {
        background: none;
        border: none;
        font-size: 1.7rem;
        cursor: pointer;
        color: #ed6c02;
    }

    .custom-alert-close-success {
        background: none;
        border: none;
        font-size: 1.7rem;
        cursor: pointer;
        color: #155724;

    }