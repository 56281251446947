

  .csv-table {
    border-collapse: separate;
    border-spacing: 0;
    
  }
  
  th {
    position: sticky;
    top: 0px;
    background-color: #F9F8F8;
    
  }
  
  .table-header-row {
    background-color: #ccc;
  }
  
  
  .table-row {
    background-color: white;
    
  }

  .table-row:not(:last-child) {
    border-bottom: 1px #ccc solid
  }
  
  .multi-upload-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;
    gap: 5px;
    width: 74px;
    margin-left: 5px;
    transition: background-color 0.1s ease-in-out;
  }
  
  .multi-upload-button:hover {
    background-color: #bbb;
  }
  
  
  
  
  /* Media query for screen width below 450px */
  @media (max-width: 750px) {
    .multi-upload-button {
      
      width: 29px;
      height: 29px;
    }
    .upload-text {
      display: none;
    }
  }
  
  
  .responsive-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #ccc;
}

.responsive-cell, .responsive-cell-2 {
    flex: 1;
    
}

@media (max-width: 550px) {
    .responsive-row {
        flex-direction: column;
        align-items: flex-start;
    }

    .responsive-cell, .responsive-cell-2 {
        width: 100%; /* Make cells stack and take full width */
        border-bottom: none; /* Remove border for stacked cells */
    }
}
