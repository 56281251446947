.equipment-component {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0px 0px;
    border: 1px solid #ccc;
    box-sizing: 'border-box';
    
    overflow: hidden;
    
}

/*min-height: calc(100vh - 75px);*/


/* Create a container around the scrollable content */


.scrollable-div {
    overflow-y: scroll;
    
}

.scrollable-div:not(:hover)::-webkit-scrollbar {
    width: 12px;
}
  
.scrollable-div::-webkit-scrollbar {
    width: 12px;  /* Adjust width */
}
  
.scrollable-div::-webkit-scrollbar-thumb {
    background: #888888;  /* Adjust thumb color */
    border-radius: 10px;  /* Roundness */
}
  
.scrollable-div::-webkit-scrollbar-thumb:hover {
    background: #555;  /* Hover effect */
}

/* Firefox */
.scrollablediv:not(:hover) {
    scrollbar-width: none;
}

.scrollablediv {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

/* IE */
.scrollablediv {
    -ms-overflow-style: none;
}

.scrollablediv:hover {
    -ms-overflow-style: scrollbar;
}