/* OrderCompletedComponent.css */

.thank-you-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
    max-width: 600px;
    margin: auto;
    margin-top: 50px;
    width:100%;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
}

.thank-you-container h1 {
    color: #339af0;
    margin-bottom: 20px;
}

.thank-you-container p {
    font-size: 16px;
    line-height: 1.6;
    color: #333;
}

.customer-service {
    margin-top: 20px;
    text-align: center;
}

.customer-service a {
    color: #339af0;
    text-decoration: none;
}

.customer-service a:hover {
    text-decoration: underline;
}
