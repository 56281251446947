/* Animation classes generated by CSSTransition */
.fragment-transition-next-enter {
  position: absolute;
  transform: translateY(-50px);
  opacity: 0;
  scale: 1.03;
}

.fragment-transition-next-enter-active {
  position: absolute;
  transform: translateY(0px);
  opacity: 1;
  scale: 1;
  transition: transform 500ms ease 300ms, opacity 500ms ease 300ms, scale 500ms ease 300ms;
}

.fragment-transition-next-exit {
  position: absolute;
  transform: translateY(0);
  opacity: 1;
  scale: 1;
}

.fragment-transition-next-exit-active {
  position: absolute;
  transform: translateY(50px);
  opacity: 0;
  scale: 0.97;
  transition: transform 500ms ease, opacity 500ms ease, scale 500ms ease;
}

/* Animation classes generated by CSSTransition */
.fragment-transition-prev-enter {
  position: absolute;
  transform: translateY(50px);
  scale: 0.97;
  opacity: 0;
}

.fragment-transition-prev-enter-active {
  position: absolute;
  transform: translateY(0px);
  opacity: 1;
  scale: 1;
  transition: transform 500ms ease 300ms, opacity 500ms ease 300ms, scale 500ms ease 300ms;
}

.fragment-transition-prev-exit {
  position: absolute;
  transform: translateY(0);
  opacity: 1;
  scale: 1;
}

.fragment-transition-prev-exit-active {
  position: absolute;
  transform: translateY(-50px);
  scale: 1.03;
  opacity: 0;
  transition: transform 500ms ease, opacity 500ms ease, scale 500ms ease;
}

/* Animation classes generated by CSSTransition */


/* Animation classes generated by CSSTransition */
@media (max-width: 900px) {
  .fragment-transition-next-enter {
    position: absolute;
    transform: translateX(-50px);
    opacity: 0;
    scale: 1;
  }

  .fragment-transition-next-enter-active {
    position: absolute;
    transform: translateX(0px);
    opacity: 1;
    scale: 1;
    transition: transform 500ms ease 300ms, opacity 500ms ease 300ms, scale 500ms ease 300ms;
  }

  .fragment-transition-next-exit {
    position: absolute;
    transform: translateX(0);
    opacity: 1;
    scale: 1;
  }

  .fragment-transition-next-exit-active {
    position: absolute;
    transform: translateX(50px);
    opacity: 0;
    scale: 1;
    transition: transform 500ms ease, opacity 500ms ease, scale 500ms ease;
  }

  .fragment-transition-prev-enter {
    position: absolute;
    transform: translateX(50px);
    scale: 1;
    opacity: 0;
  }

  .fragment-transition-prev-enter-active {
    position: absolute;
    transform: translateX(0px);
    opacity: 1;
    scale: 1;
    transition: transform 500ms ease 300ms, opacity 500ms ease 300ms, scale 500ms ease 300ms;
  }

  .fragment-transition-prev-exit {
    position: absolute;
    transform: translateX(0);
    opacity: 1;
    scale: 1;
  }

  .fragment-transition-prev-exit-active {
    position: absolute;
    transform: translateX(-50px);
    scale: 1;
    opacity: 0;
    transition: transform 500ms ease, opacity 500ms ease, scale 500ms ease;
  }
}

.contacts-wrapper {
  padding-top: 200px;
}

.contacts-left-panel {}

@media (max-width: 840px) {
  .contacts-left-panel {
    display: none;
  }

  .contacts-wrapper {
    padding-top: 100px;
    height: 100%;
  }
}