/* HeaderComponent.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


.tw-ul-style,
.tw-ol-style {
    list-style: none;
    margin: 0;
    padding: 0;
}

.tw-blockquote,
.tw-dl,
.tw-dd,
.tw-h1,
.tw-h2,
.tw-h3,
.tw-h4,
.tw-h5,
.tw-h6,
.tw-hr,
.tw-figure,
.tw-p,
.tw-pre {
  margin: 0;
}

.tw-h1,
.tw-h2,
.tw-h3,
.tw-h4,
.tw-h5,
.tw-h6 {
  font-size: inherit;
  font-weight: inherit;
}

.tw-img,
.tw-svg,
.tw-video,
.tw-canvas,
.tw-audio,
.tw-iframe,
.tw-embed,
.tw-object {
  display: block;
  vertical-align: middle;
}

.tw-img,
.tw-video {
  max-width: 100%;
  height: auto;
}


.tw-::before,
.tw-::after {
  border-width: 0;
  border-style: solid;
  border-color: theme('borderColor.DEFAULT', currentColor);
}

.header {
    background-color: #f2f2f2;
    padding: 10px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
}

.cart-button {
    background-color: #339af0;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 0.9em;
    transition: background-color 0.1s ease;

    /* Remove default button styles */
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.cart-button:hover {
    background-color: #257ab3; /* a slightly darker shade of the primary blue */
}

.custom-google-sign-in {
    background-color: #4285f4; /* Google's brand color */
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 2px;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: adds a subtle shadow */
    /* Add other styles as needed */
  }
  
  /* Add styles for when the user is hovering over the button */
  .custom-google-sign-in:hover {
    background-color: #357ae8; /* A slightly darker shade of blue for hover effect */
  }

  /* Add to your HeaderComponent.css */
.menu-icon {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 25px;
    background: transparent;
    border: none;
    outline: none;
  }
  
  .bar {
    height: 3px;
    width: 100%;
    background-color: #333;
    border-radius: 10px;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s, visibility 0.5s;
  }
  
  .overlay.show {
    visibility: visible;
    opacity: 1;
  }
  
  
    
  
  
  