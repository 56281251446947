




  
  
    
  
  
.toggle-container {
    display: flex;
    align-items: center;
    font-family: Arial, sans-serif;
    font-size: 16px;
    padding: 4px;
    border-radius: 8px;
    font-weight: 500;
    transition: background-color 0.1s ease-in-out;
    cursor: pointer;
  }

  .toggle-container:hover {
    background-color: #eee;
  }

  @media (max-width: 450px) {
    .toggle-container {
        display: none
    }
  }
  
  .toggle-label {
    font-family: var(--font-family-1);
    color: var(--font-color-1);
    font-size: 14px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }

  .header-nav-bar {
    justify-content: space-between;
  }

  @media (min-width: 900px) {
    .toggle-container {
      justify-content: right;
    }
    .header-nav-bar {
      justify-content: right;
    }
  }

  
  
  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.3s;
    border-radius: 20px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.3s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #339AF0;
  }
  
  input:checked + .slider:before {
    transform: translateX(20px);
  }
  

  .test-data-description {
    width: 100%;
    font-family: var(--font-family-1);
    color: white;
    font-size: 14px;
    display: flex;
    justify-content: center;
  }

  .test-data-test-mode {
    display: flex;
    width: 100px;
    font-family: var(--font-family-1);
    color: white;
    font-size: 14px;
    font-weight: 500;
  }



  @media (max-width: 550px) {
    .test-data-description {
        display: none;
    }

    .test-data-test-mode {
        margin-right: auto
    }
  }