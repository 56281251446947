/* PartTypeSelectorComponent.css */

.part-type-selector-container {
  z-index: 9;
  padding: 10px;
  border: 1px solid #ccc;
  border-bottom: none;
  border-radius: 0px;
  min-width: 200px;
  margin-right: 5px;
  position: absolute;
  background-color: white;
  height: calc(100vh - 155px + 18px);
  display: flex;
  flex-direction: column;
}

.part-type-search-bar-container {
  margin-bottom: 10px;
}

.part-type-search-bar {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.part-type-button-container {
  flex-grow: 1;
  overflow-y: auto;
}

.part-type-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.part-type-button:hover {
  background-color: #f5f5f5;
}

.part-type-button.selected {
  background-color: #339af0;
  color: #fff;
}

.part-type-button .radio-button {
  display: inline-block;
  width: 4px;
  height: 4px;
  margin-right: 10px;
  border-radius: 50%;
  border: 1px solid black;
}

.part-type-button.selected .radio-button {
  background-color: #339af0;
  border-color: #339af0;
}

.part-type-button.selected:hover {
  background-color: #1976d2;
  color: white;
}

.radio-button {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  border-radius: 50%;
  border: 1px solid #ccc;
}

/* Additional button style for the part-type-button2 if needed */
.part-type-button2 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%;
}

.part-type-button2:hover {
  background-color: #f5f5f5;
}

.part-type-button2.selected {
  background-color: #339af0;
  color: #fff;
}

.part-type-button2 .radio-button {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  border-radius: 50%;
  border: 1px solid #ccc;
}

.part-type-button2.selected .radio-button {
  background-color: #339af0;
  border-color: #339af0;
}

.part-type-button2.selected:hover {
  background-color: #1976d2;
  color: white;
}
