.order-history-navigation {
    width: 100%;
    overflow-x: auto;
    scrollbar-width: thin;
}

.order-history-button {
    border: 1px solid #ccc;
    border-radius: 11px;
    min-width: 168px;
    width: 100%;
    max-width: 207px;
    padding: 11px;
}

@media (max-width: 900px) {
    .order-history-button {
        min-width: 100px;
    }
}

.order-history-table {
    width: 100%;
}