/* Remove the pulse animation and create a pseudo-element for the gradient border */
.pulseBorder {
    position: relative;
    /* Use a transparent border so that the pseudo-element’s gradient shows through */
    border: 4px solid transparent;
    border-radius: 4px;
    /* Optional: set a background color for the content area */
    background: #fff;
    z-index: 1;


    background: linear-gradient(90deg, #ebebeb, #f5f5f5, #ebebeb);
    background-size: 200% auto;
    animation: gradientTranslate 1.5s linear infinite;
  }
  
  .pulseBorder::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: inherit;
    


    z-index: -1;
  }
  
  @keyframes gradientTranslate {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
  