.order-detail-grid {
    display: grid;
    grid-template-columns: minmax(100px, 1fr);
    /* single column for mobile */
    gap: 50px 10px;
    grid-template-areas:
        "recent-activity"
        "payment-breakdown"
        "order-breakdown"
        "details";
}

/* Assign grid areas */
.recent-activity {
    grid-area: recent-activity;
}

.details {
    grid-area: details;
}

.payment-breakdown {
    grid-area: payment-breakdown;
}

.order-breakdown {
    grid-area: order-breakdown;
}

/* For larger screens, you might want a different layout */
@media (min-width: 700px) {
    .order-detail-grid {
        grid-template-columns: 70% 30%;
        grid-template-areas:
            "recent-activity details"
            "payment-breakdown details"
            "order-breakdown details";
    }
}

.breakdown-grid {
    display: grid;
    grid-template-columns: minmax(150px, 150px) minmax(0%, 100%);
    gap: 5px 10px;
    /* row gap, column gap */
}

.breakdown-grid-2 {
    display: grid;
    grid-template-columns: minmax(150px, 150px) minmax(0%, 100%);
    gap: 5px 10px;
    /* row gap, column gap */
}

@media (max-width: 400px) {
    .breakdown-grid {
        grid-template-columns: 1fr;
        gap: 5px;
    }
}