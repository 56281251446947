.footer1 {
    position: relative; /* Parent needs relative positioning */
  }
  
  .footer1::after {
    content: ''; /* Required */
    position: absolute;
    height: 300%;
    background-color: #dae1f4;
    /* Add these properties as needed */
    width: 100%;
    left: 0;
    top: 0; /* or bottom: 0 depending on your needs */
    /* Add any background, z-index, or other styling */
  }