.checkout-right-panel-small {
    display: flex;
}

.hidden-content-hover {
    cursor: pointer;
}

.hidden-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;

}

.hidden-content.show {
    
    max-height: 500px;
    /* Adjust to fit content */
}

.small-checkout-wrapper {
    width: 100%;
   background-color:  #F6F9FC;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    margin-top: 16px;
}