/* HeaderComponent.css */

.header {
    background-color: #f2f2f2;
    padding: 10px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
}

.cart-button {
    background-color: #339af0;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 0.9em;
    transition: background-color 0.1s ease;

    /* Remove default button styles */
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.cart-button:hover {
    background-color: #257ab3; /* a slightly darker shade of the primary blue */
}
