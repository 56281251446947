.table-style {
    width: 100%;
    border-collapse: collapse;
    text-align: left;

}

.table-style thead {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.table-style thead tr td {
    font-family: var(--font-family-1);
    font-size: 12px;
    color: #1A1B25;
    font-weight: 600;

    padding: 8px 8px 8px 0px;
    white-space: nowrap;
}

.table-style-small :global(span.ts-th) {
    font-family: var(--font-family-1);
    font-size: 12px;
    color: #1A1B25;
    font-weight: 600;

    white-space: nowrap;
}

.table-style tbody tr td:last-child {
    width: 100%;
}

.table-style tbody tr td:first-child,
.table-style thead tr td:first-child {
    text-align: right;
}

.table-style tbody tr:last-child td,
.table-style-small div:last-child {
    border-bottom: none;
}

.table-style tbody tr td {
    font-family: var(--font-family-1);
    font-size: 13px;

    padding: 8px 8px 8px 0px;
    white-space: nowrap;
    overflow-wrap: anywhere;
    border-bottom: solid 1px #ddd
}

.table-style-small > div {
  
    transition: ease-in-out 200ms background-color;
}

.table-style-small > div:hover {
    background-color: #f7f7f7;
    transition: ease-in-out 200ms background-color;
    cursor: pointer
}

.table-style-small > div:not(:last-child) {
    border-bottom: 1px solid #ddd;
}


.table-style tbody tr {
    transition: ease-in-out 200ms background-color;
}

.table-style tbody tr:hover {
    background-color: #f7f7f7;
    transition: ease-in-out 200ms background-color;
    cursor: pointer
}


/* small window */
.table-style-small {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    display: none;
}

@media (max-width: 500px) {
    .table-style-small {
        display: block
    }

    .table-style {
        display: none
    }
}