.checkout-right-panel {
    width: 100%;
    min-height: 750px;
    overflow: hidden; /* Hide overflow */
}

.checkout-right-panel::after {
    content: '';
    position: absolute;
    top: 0;
    width: 300%;
    height: 100%;
    min-height: 750px;
    overflow: hidden; /* Ensure no overflow */
    background-color: rgb(246, 249, 252);
    z-index: -2;
    border-left: 1px solid #ccc;
}


.checkout-right-panel-wrapper {
    height: 100%;
}

.checkout-padding {
    padding: 16px 16px 16px 16px;
    height: 100%;
}

.checkout-right-panel-small {
    display: none;
}


@media (max-width: 900px) {
    .checkout-right-panel {
        display: none;
    }
    .checkout-padding {
        padding: 16px;
        height: auto;
    }
    .checkout-right-panel-small {
        display: flex;
    }
}

.hidden-content-hover {
    cursor: pointer;
}

.hidden-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
  .hidden-content.show {
    max-height: 200px; /* Adjust to fit content */
  }


.remove-part-container {
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    background-color: transparent;
    border-radius: 10px;
    transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out, visibility 0.2s ease-in-out;
    z-index: 2;
}

.image-container:hover  + .remove-part-container,
.remove-part-container:hover {
    visibility: visible;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: #ccc;
    border-radius: 10px;
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out, visibility 0.2s ease-in-out;
    z-index: 2;
}