.scaling-box-grid {
  display: grid;
  grid-template-columns: repeat(6, 78px);
  gap: 12px;
  background-color: #F6F9FC;
  justify-content: center;
  position: relative; /* Ensure stacking context */
  z-index: 1; /* Keep the grid above the ::before element */
  padding: 10px 0px;
}

.scaling-box-wrapper {
  position: relative; /* Create a positioning context for ::before */
  overflow: hidden; /* Prevent overflow */
  
}

.scaling-box-wrapper::before {
  content: '';
  position: absolute;
  top: 0; /* Position at the top of the wrapper */
  left: 0; /* Align to the left edge */
  width: 100%; /* Confine to the width of the parent */
  height: 100%; /* Match the height of the wrapper */
  background-color: #F6F9FC;
  z-index: -1; /* Place it behind the content */
}



  .scaling-box {
    position: relative;
    height: 78px;
    width: 78px;
    background-color: #F6F9FC;
    border: 1px solid #ccc;
    border-radius: 8px;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out;
    box-shadow: none;
    z-index: 2;
  }
  
  .scaling-box:hover:not(.animating) {
    transform: scale(1.1);
    background-color: white;
    border: 1px solid white;
    border-radius: 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: 
      transform 0.3s ease-in-out, 
      bottom 0.3s ease-in-out, 
      opacity 0.2s ease-in-out,
      color 0.3s ease-in-out,
      box-shadow 0.3s ease-in-out,
      border 0.3s ease-in-out,
  }

  .scaling-box .scaling-box-label {
    opacity: 0;
    transform: scale(1);
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    text-align: center;
    color: white;
    transition: 
    transform 0.3s ease-in-out, 
    bottom 0.3s ease-in-out, 
    opacity 0.2s ease-in-out,
    color 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out,
  }

  .scaling-box:hover:not(.animating) .scaling-box-label {
    transform: scale(1);
    position: absolute;
    width: 100%;
    font-size: 12px;
    font-family: var(--font-family-1);
    font-weight: 500;
    bottom: 10px;
    left: 0;
    text-align: center;
    opacity: 1;
    color: black;
    
    transition: 
    transform 0.3s ease-in-out, 
    bottom 0.3s ease-in-out, 
    opacity 0.2s ease-in-out,
    color 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out,
}


  .scaling-box.PID .scaling-box-label,
  .scaling-box.COM .scaling-box-label,
  .scaling-box.SAFE .scaling-box-label,
  .scaling-box.PD .scaling-box-label,
  .scaling-box.animating .scaling-box-label {
    transform: scale(1);
    font-size: 12px;
    font-family: var(--font-family-1);
    font-weight: 500;
    opacity: 1;
    color: black;
    bottom: 10px;
    left: 0;
    text-align: center;
    opacity: 1;
    color: black;
    
    transition: 
    transform 0.3s ease-in-out, 
    bottom 0.3s ease-in-out, 
    opacity 0.1s ease-in-out,
    color 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out,
    }
  
  .scaling-box.animating {
    animation: scaleAnimation 5s ease-in-out;
  }

  .scaling-box.PID {
    animation: scaleAnimationPID 9.25s ease-in-out;
  }

  .scaling-box.COM {
    animation: scaleAnimationCOM 20s ease-in-out;
  }

  .scaling-box.SAFE {
    animation: scaleAnimationSAFE 20s ease-in-out;
  }

  .scaling-box.PD {
    animation: scaleAnimationPD 9.25s ease-in-out;
  }

  

  
  @keyframes scaleAnimation {
    0% {
      transform: scale(1);
      background-color: #F6F9FC;
      border: 1px solid #ccc;
      border-radius: 8px;
      box-shadow: none;
    }
    5% {
      transform: scale(1.1);
      background-color: white;
      border: 1px solid white;
      border-radius: 8px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }
    95% {
        transform: scale(1.1);
        background-color: white;
        border: 1px solid white;
        border-radius: 8px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      }
    100% {
      transform: scale(1);
      background-color: #F6F9FC;
      border: 1px solid #ccc;
      border-radius: 8px;
      box-shadow: none;
    }
  }

  @keyframes scaleAnimationPID {
    0% {
      transform: scale(1);
      background-color: #F6F9FC;
      border: 1px solid #ccc;
      border-radius: 8px;
      box-shadow: none;
    }
    2.5% {
      transform: scale(1.1);
      background-color: white;
      border: 1px solid white;
      border-radius: 8px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }
    97.5% {
        transform: scale(1.1);
        background-color: white;
        border: 1px solid white;
        border-radius: 8px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      }
    100% {
      transform: scale(1);
      background-color: #F6F9FC;
      border: 1px solid #ccc;
      border-radius: 8px;
      box-shadow: none;
    }
  }

  @keyframes scaleAnimationCOM {
    0% {
      transform: scale(1);
      background-color: #F6F9FC;
      border: 1px solid #ccc;
      border-radius: 8px;
    }
    2.5% {
      transform: scale(1.1);
      background-color: #ccc;
      border: 1px solid #ccc;
      border-radius: 8px;
    }
    97.5% {
        transform: scale(1.1);
        background-color: #ccc;
        border: 1px solid #ccc;
        border-radius: 8px;
      }
    100% {
      transform: scale(1);
      background-color: #F6F9FC;
      border: 1px solid #ccc;
      border-radius: 8px;
    }
  }

  @keyframes scaleAnimationSAFE {
    0% {
      transform: scale(1);
      background-color: #F6F9FC;
      border: 1px solid #ccc;
      border-radius: 8px;
    }
    2.5% {
      transform: scale(1.1);
      background-color: #ccc;
      border: 1px solid #ccc;
      border-radius: 8px;
    }
    97.5% {
        transform: scale(1.1);
        background-color: #ccc;
        border: 1px solid #ccc;
        border-radius: 8px;
      }
    100% {
      transform: scale(1);
      background-color: #F6F9FC;
      border: 1px solid #ccc;
      border-radius: 8px;
    }
  }

  @keyframes scaleAnimationPD {
    0% {
      transform: scale(1);
      background-color: #F6F9FC;
      border: 1px solid #ccc;
      border-radius: 8px;
    }
    2.5% {
      transform: scale(1.1);
      background-color: #ccc;
      border: 1px solid #ccc;
      border-radius: 8px;
    }
    97.5% {
        transform: scale(1.1);
        background-color: #ccc;
        border: 1px solid #ccc;
        border-radius: 8px;
      }
    100% {
      transform: scale(1);
      background-color: #F6F9FC;
      border: 1px solid #ccc;
      border-radius: 8px;
    }
  }
  

  
  
  @keyframes draw-line {
    0% {
      stroke-dashoffset: 300; /* Start hidden */
    }
    20% {
        stroke-dashoffset: 0;
    }
    80% {
        stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: -300; /* Fully revealed */
    }
  }

  @keyframes pulse-color {
    0% {
      stroke: blue;
    }
    25% {
      stroke: cyan;
    }
    50% {
      stroke: blue;
    }
    75% {
      stroke: cyan;
    }
    100% {
      stroke: blue;
    }
  }