.profile-contact-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;
    min-width: 850px;
    max-width: 850px;
    
}

.profile-contact-details .contact-details h2 {
    color: #333;
    margin-bottom: 20px;    
}

.profile-contact-details .form-group {
    width: 100%;
    margin-bottom: 20px;
}

.profile-contact-details .form-group label {
    display: block;
    margin-bottom: 8px;
    color: #666;
    font-weight: bold;
}

.profile-contact-details .form-group input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-top: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.profile-contact-details .name-container {
    display: flex;
    gap: 20px;
}

.profile-contact-details .name-group {
    flex: 1;
}

.profile-contact-details .contact-number-container {
    display: flex;
    gap: 10px;
}

.profile-contact-details .contact-number-container input {
    flex: 1;
}

.profile-edit-button,
.profile-save-button {
    padding: 10px 20px;
    
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.profile-edit-button:hover,
.profile-save-button:hover {
    background-color: #0056b3;
}

.profile-edit-button:disabled,
.profile-save-button:disabled {
    background-color: #ccc;
    cursor: default;
}




body.ProfileEntrypoint .step__footer {
    margin-bottom: 16px;
}

body.ProfileEntrypoint * {
    outline-color: #212a2f;
}

body.ProfileEntrypoint .step__footer {
    margin-bottom: 16px;
}


@media only screen and (min-width: 992px) {
    body.ProfileEntrypoint .step__footer__continue-btn {
        margin-bottom: 0;
    }
}


@media only screen and (min-width: 992px) {
    body.ProfileEntrypoint .btn {
        display: block;
    }
}

.ProfileEntryPoint {
    box-sizing: border-box;
}

body.ProfileEntryPoint {
    font-family: Geograph, Helvetica, Arial, sans-serif !important;
    font-size: 14px;
    font-weight: 400 !important;
    letter-spacing: .5px;
    line-height: 1.5;
    font-smooth: auto;
    -webkit-font-smoothing: auto;
}

.profile-wrap {
    overflow-x: visible;
    width: 80%;
    height: 100%;
    margin: 0 auto;
    display: "flex";
    flex-direction: column;
    max-width: 40em;
    zoom: 1;
}

@media (min-width: 1000px) {
    .profile-wrap {
        width: 40%;
        max-width: 78.5714285714em;
    }
}


.profile-wrap {
    display: block;
    margin: 0 auto;
    zoom: 1;
}


