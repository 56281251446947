/* LandingPage.css */



.landing-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  overflow-x: hidden;
}

@media (min-width: 1px) {
  .intro {
    margin: 0 0;
    padding-left: 0px;

  }

  .main-content {
    padding: 0 0;
  }

  .call-to-action {
    max-width: 85%;
  }
}

@media (min-width: 490px) {
  .intro {
    margin: 0 0;
    padding-left: 0px;

  }

  .main-content {
    padding: 0 0;
  }

  .call-to-action {
    max-width: 80%;
  }
}

@media (min-width: 768px) {
  .intro {
    margin: 0 5%;
    padding-left: 30px;
  }

  .intro-content {
    margin-left: 5%;
    margin-right: 5%;
  }

  .call-to-action {
    max-width: 75%;
  }
}

@media (min-width: 1024px) {
  .intro {
    margin: 0 7%;
    padding-left: 60px;
  }

  .intro-content {
    margin-left: 10%;
    margin-right: 10%;
  }

  .call-to-action {
    max-width: 70%;
  }
}



.header {
  background: #f8f8f8;
  padding: 20px;
  text-align: center;
}

.main-content {
  height: var(--vh);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.intro,
.features,
.steps {
  padding: 20px;

}

@keyframes slideBackground {
  from {
    background-position: 0% 50%;
  }

  to {
    background-position: -300% 50%;
    /* Moves three times the container's width */
  }
}






.intro {
  height: var(--vh);
  /* 100% of the viewport height */
  display: flex;
  /*align-items: center; /* Center content vertically */
  color: white;
  /* Text color */


  text-align: center;
  /* Center text alignment */

  /* Background image */
  /*background: url('C:\Zephyr Project\Zephyr-Website-Main-v3-main\frontend\public\photo-1503385824845-4f3407ce5e03.jpg') no-repeat center center fixed;*/
  background-size: cover;
  /* Ensure the image covers the entire background */


  /* Optional: Add a gradient overlay for better text visibility */
  position: absolute;
  top: 0px;
  left: 0px;

  transition: margin 0.5s ease-in-out;



}



.call-to-action {
  transition: max-width 0.5s ease-in-out;
}

.intro::before {
  /*content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3); /* Black overlay with 50% opacity */

  z-index: 1;
  /* Ensure the overlay is above the background image */

}

.intro>* {
  position: relative;
  z-index: 2;
  /* Ensure the content is above the overlay */
}


.intro-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  transition: margin-left 0.5s ease-in-out
}

.intro-content>div {
  font-size: 1.5em;
  /* Increase font size */

  margin: 10px 0;
  /* Add some space between the divs */
}

.intro-content>div:first-child {
  font-size: 2em;
  /* Even larger font size for the first line */
}

.intro-content>div:last-child {
  font-size: 1.2em;
  /* Adjust the font size for the login link */

}

.landing-page body,
.landing-page h1,
.landing-page h2,
.landing-page h3,
.landing-page p {
  margin: 0;
}


.button-container:hover+.icon-container {
  margin-left: 30px;
}







.test-background {
  width: 100%;
  overflow: hidden;
  height: 670px;
  background: linear-gradient(150deg, #53f 15%, #05d5ff 70%, #a6ffcb 94%);
  transform: skewY(-12deg);
  transform-origin: 0;
  position: absolute;
  top: 0;
}

.test-background span {
  height: 190px;
  position: absolute;
}

.test-background span:nth-child(1) {
  width: 33.3%;
  width: calc(100% / 3);
  left: -16.66666%;
  left: calc(calc(calc(100% / 3) /2) * -1);
  background: #53f;
}

.test-background span:nth-child(2) {
  width: calc(100% / 3);
  top: 0;
  left: calc(calc(100% / 3) /2);
  right: auto;
  background: #4553ff;
}

.test-background span:nth-child(3) {
  width: 100%;
  left: calc(calc(calc(100% /3)/2) + calc(100% /3));
  bottom: auto;
  background: #4f40ff;
}

.test-background span:nth-child(4) {
  width: calc(100% / 3);
  bottom: 0;
  background: #1fa2ff;
}




.exmaple-filter-by-id {
  min-height: 30px;
  width: 200px;
  padding: 4px 24px 4px 28px;
  border: 1px solid #d8dee4;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  outline: none;
}

.scaling-box-grid-wrapper {
  width: 100%;
  top: calc(50% - 400px);
  position: sticky;
  height: var(--vh);
}

.component-explaination {
  height: 100%;
  padding-bottom: 200px;
}

@media (max-width: 1100px) {
  .scaling-box-grid-wrapper {
    display: none
  }


}

.login-button:hover {
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.1s ease-in-out;
}




@media (max-width: 550px) {
  .contact-sales {
    display: none;
  }
}