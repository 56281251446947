.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 3 columns, each taking an equal fraction of space */
    grid-gap: 20px;
    /* Adds spacing between grid items */
    padding: 0px;
    padding-bottom: 100px;
   
}


.grid-item {
    background-color: white;
    
    /*border: 1px solid #ddd;*/
    text-align: center;
    padding: 12px;
    font-size: 18px;
    min-height: 330px;
}


@media (max-width: 1340px) {
    .grid-container {
        grid-template-columns: repeat(2, 1fr);
    }

    
}

@media (max-width: 900px) {
    .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
}


.bar-segment:hover {
    cursor: pointer;
  }
  
  .bar-segment-child {
    pointer-events: none; /* Prevent child from triggering parent hover */
    opacity: 0;
    transform: translateX(10px); /* Start slightly offset to the right */
    display: flex;
    transition: 
      transform 200ms ease-in-out,
      opacity 200ms ease-in-out;
  }
  
  .bar-segment {
    opacity: 1;
    transition:
      opacity 200ms linear;
  
  }
  
  .bar-segment:hover {
    opacity: 0.9;
    transition:
      opacity 200ms linear;
  }
  
  .bar-segment:hover .bar-segment-child {
    opacity: 1;
    transform: translateX(0); /* Moves to its final position */
  }
  
  .bar-segment:not(:hover) .bar-segment-child {
    opacity: 0;
    transform: translateX(-10px); /* Slides to the left when exiting */
  }
  
  .segment-chart-dates {
    opacity: 1;
    transition:
      opacity 200ms linear;
  }
  
  .segment-chart-container:hover + .segment-chart-dates{
    opacity: 0;
    transition:
      opacity 200ms linear;
  }





  .dash-bar-chart-wrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .dash-bar-chart {
    width: 70%;
  }

  .task-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 30%;
  }

  .task-info-2 {
    border-top: 1px solid #eee;
  }

  @media (max-width: 900px) {
    .dash-bar-chart-wrapper {
        flex-direction: column;
    }

    .dash-bar-chart {
        width: 100%;
    }

    .task-info-wrapper {
        flex-direction: row;
        width: 100%;
    }

    .task-info-1 {
        width: 100%;
    }

    .task-info-2 {
        border-top: none;
        width: 100%;
    }
  }